@font-face {
    font-family: 'Metropolis Semi';
    src: url('../assets/fonts/Metropolis/Metropolis-SemiBold.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Medium';
    src: url('../assets/fonts/Metropolis/Metropolis-Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../assets/fonts/Metropolis/Metropolis-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.react-datepicker {
    font-family: 'Metropolis', sans-serif;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding: 16px;
    border: none;
}

.react-datepicker.react-datepicker--middlestep {
    display: flex;
    flex-direction: column;
}

.react-datepicker--middlestep .react-datepicker__navigation--next {
    display: none;
}

.react-datepicker--middlestep .react-datepicker__day-names {
    display: none;
}

.react-datepicker.closed {
    display: none;
}

.react-datepicker-popper {
    transform: translate3d(-30px, -80px, 0px) !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__input-container input {
    font-family: 'Metropolis', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    width: 80%;
}

.react-datepicker__input-container input::placeholder {
    color: rgba(55, 55, 55, .5);
}

/* Header */

.react-datepicker__header {
    background: white;
    border: none;
}

.react-datepicker__navigation {
    outline: none;
    border: none;
    border-top: 2px solid #08c5b9;
    border-right: 2px solid #08c5b9;
}

.react-datepicker__navigation:hover {
    border-color: #08c5b9;
}

.react-datepicker__navigation--next {
    right: 20px;
    top: 24px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
}

.react-datepicker__navigation-icon--next {
    display: none;
}

.react-datepicker__navigation--previous {
    left: 20px;
    top: 24px;
    transform: rotate(-135deg);
    width: 10px;
    height: 10px;
}

.react-datepicker__navigation-icon--previous {
    display: none;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 37px;
}

.react-datepicker--middlestep .react-datepicker__day-name {
    width: 12%;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: center;
}

.react-datepicker__current-month .react-datepicker__day {
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
}


/* Day */

.react-datepicker__day,
.react-datepicker__day:hover {
    display: inline-flex;
    justify-content: center;
    outline: none;
    font-size: 16px;
    border-radius: 0;
    font-weight: 500;
    width: 14%;
    height: auto;
    margin: 4px 0;
    background: white !important;
}

.react-datepicker__day--keyboard-selected {
    color: black;
}

.react-datepicker__day--in-range.react-datepicker__day:hover>.react-datepicker__day--inner,
.react-datepicker__day--selecting-range-end .react-datepicker__day--inner,
.react-datepicker__day:hover>.react-datepicker__day--inner {
    border: 1px solid #08c5b9;
    background: white;
    border-radius: 50%;
    box-sizing: border-box;
    color: black;
}

.react-datepicker__day--in-range {
    color: black;
}

.react-datepicker__day--disabled {
    font-weight: 400;
    color: #ccc !important;
    background: unset !important;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
    background: white;
}

.react-datepicker__month--selecting-range .react-datepicker__day--disabled {
    color: #ccc;
}

.react-datepicker__day--in-range.react-datepicker__day--has-start,
.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--has-start,
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range.react-datepicker__day--has-start,
.react-datepicker__month--selecting-range .react-datepicker__day--in-range.react-datepicker__day--has-start,
.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range {
    border-radius: 0;
    color: black;
    background: #E6F9F7;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--has-end,
.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--has-end.react-datepicker__day--selecting-range-end,
.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--has-start.react-datepicker__day--selecting-range-start {
    background: #fff;
    color: black;
}

.react-datepicker__month--selecting-range .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end,
.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--has-start {
    background: white;
}

.react-datepicker__day--range-start.react-datepicker__day--has-start,
.react-datepicker__day--range-start.react-datepicker__day--end.react-datepicker__day--in-selecting-range,
.react-datepicker__day.react-datepicker__day--selecting-range-start,
.react-datepicker__month--selecting-range .react-datepicker__day.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--has-start.react-datepicker__day--has-end {
    color: white;
    background: linear-gradient(to right, white, white 50%, #E6F9F7 50%, #E6F9F7);
}

.react-datepicker__day--range-end.react-datepicker__day--has-start,
.react-datepicker__day.react-datepicker__day--selecting-range-end.react-datepicker__day--has-start,
.react-datepicker__month--selecting-range .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--has-start.react-datepicker__day--has-end {
    color: white;
    background: linear-gradient(to left, white, white 50%, #E6F9F7 50%, #E6F9F7);
}

.react-datepicker__day--inner {
    width: 34px;
    height: 34px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    line-height: 34px;
    margin-left: 4px;
    margin-right: 4px;
}

.react-datepicker__day--range-start.react-datepicker__day--end {
    background: white;
}

.react-datepicker__day--range-end .react-datepicker__day--inner,
.react-datepicker__day--range-start .react-datepicker__day--inner {
    color: white;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #08c5b9;
}




@media (max-width: 768px),
(max-height: 550px) {
    .react-datepicker:not(.react-datepicker--time-only) {
        padding-top: 120px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
    }

    .react-datepicker__day--inner {
        margin: 0 auto;
    }

    .react-datepicker__portal .react-datepicker__day-name {
        width: 12%;
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__portal .react-datepicker__current-month,
    .react-datepicker__portal .react-datepicker-time__header {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .react-datepicker__day-names,
    .react-datepicker--middlestep .react-datepicker__day-names {
        width: 100%;
        position: fixed;
        top: 64px;
        background: white;
        opacity: 1;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        left: 0;
    }

    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 14%;
    }

    .react-datepicker__day--outside-month,
    .react-datepicker__day--outside-month:hover {
        visibility: hidden;
    }

    .react-datepicker:not(.react-datepicker--time-only) {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        height: 100vh;
        width: 100vw;
    }

    .react-datepicker__navigation--next {
        visibility: hidden;
    }

    .react-datepicker__navigation--previous {
        visibility: hidden;
    }

    .react-datepicker__day--in-range.react-datepicker__day:hover>.react-datepicker__day--inner,
    .react-datepicker__day--selecting-range-end .react-datepicker__day--inner,
    .react-datepicker__day:hover>.react-datepicker__day--inner {
        border: none;
    }
}