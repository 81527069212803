@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
  font-family: 'Metropolis SemiBold';
  src: url('./fonts/Metropolis/Metropolis-SemiBold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis Medium';
  src: url('./fonts/Metropolis/Metropolis-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  img {
    max-width: -webkit-fill-available;
  }
}

.custom-button {
  display: inline-block;
  padding: 20px 40px 20px 40px;
  background-color: #08c5b9;
  color: white !important;
  font-weight: bold;
  margin: 10px;
  border-radius: 27.5px;
}
